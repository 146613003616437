<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Tarif groupe" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Modifier un tarif groupe</div>
              </v-row>
            </v-card-title>
            
            <!-- le produit -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">produit</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    :items="availableProducts"
                    item-text="digitalName"
                    item-value="digitalName"
                    v-model="selectedProduct"
                    no-data-text="aucun produit"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le nom -->
            <!-- <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="label-field capitalize">nom</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field dense v-model="name"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text> -->

            <!-- la description -->
            <!-- <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="label-field capitalize">description</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-textarea dense v-model="description"></v-textarea>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text> -->

            <!-- le type de tarifs -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">type de tarif</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    :items="allPriceTypes"
                    item-text="label"
                    item-value="label"
                    v-model="selectedPriceType"
                    no-data-text="aucun type de tarif"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le ou les montants -->
            <v-card-text >
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="label-field capitalize">montant(s)</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <div 
                  v-for="(amount, i) in availableAmount" :key="i"
                  v-show="availableAmount > 0">
                    <v-text-field 
                    dense 
                    v-model="amounts[i]"
                    type="number"
                    min="0"
                    step=".01"
                    :rules="[rules.positive, rules.max(i)]"
                    @blur="onQuitAmount(i)"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- l'unité de tarifs -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">unité de tarif</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    :items="allPriceUnits"
                    item-text="label"
                    item-value="label"
                    v-model="selectedPriceUnit"
                    no-data-text="aucune unité de tarif"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
                >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Fenetre modale pour éviter que l'utilisateur quitte sans sauvegarder -->
    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { ProductPriceGroupeService } from "@/service/conf/product_price_groupe_service.js"
import { AvecProductsService } from "@/service/conf/avec_products_service.js";
import { PriceTypesService } from "@/service/conf/price_types_service.js"
import { PriceUnitsService } from "@/service/conf/price_units_service.js"

import { areTheSame } from "@/tools/string_tool.js";
import { numberWithMinTwoDecimal } from "@/tools/number_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditPriceGroupe",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service de la vue */
      service: null,
      serviceProduct: null,
      servicePriceTypes: null,
      servicePricesUnits: null,

      /** l'identifiant à éditer */
      entityId: null,

      /** la source */
      source: null,

      /** L'ensemble des produits */
      allProducts: [],
      /** L'ensemble des types de tarifs */
      allPriceTypes: [],
      /** L'ensemble des unités de tarifs */
      allPriceUnits: [],
      /** L'ensemble des tarifs groupe */
      allPricesGroup: [],

      /** le produit sélectionné */
      selectedProduct: null,
      /** le type de tarif sélectionné */
      selectedPriceType: null,
      /** l'unité de tarif sélectionné */
      selectedPriceUnit: null,

      /** le nom */
      name: null,
      /** la description */
      description: null,
      /** les montants */
      amounts: [],

      /** Les règles de saisie */
      rules: {
        positive: (value) => this.pricePositiveRules(value),
        max: (value) => this.pricesCroissant(value),
      },
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // Récupération du tarif
        let entity = await this.service.getById(this.entityId);
        // Ajout ç la source pour l'analyse des changements
        this.source = JSON.parse(JSON.stringify(entity));

        // Récupère la liste des produits (transmissions)
        this.allProducts = await this.serviceProduct.getAll();
        // Récupère la liste des type de tarifs
        this.allPriceTypes = await this.servicePriceTypes.getAll();
        // Récupère la liste des unité de tarifs
        this.allPriceUnits = await this.servicePricesUnits.getAll();
        // Récupère l'ensemble des tarifs groupe
        this.allPricesGroup = await this.service.getAll();


        // Récupération du produit associé
        this.selectedProduct = this.allProducts.find((p) => p.id == entity.productId);
        // Récupération du type associé
        this.selectedPriceType = this.allPriceTypes.find((t) => t.id == entity.priceType);
        // Récupération de l'unité associée
        this.selectedPriceUnit = this.allPriceUnits.find((u) => u.id == entity.priceUnit);

        this.init();

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Initialise les datas de la vue en fonction de la source */
    init() {
      this.name = this.source.name || "";
      this.description = this.source.description || "";

      this.amounts = [];
      for (let amount of this.source.amounts) {
        let price = numberWithMinTwoDecimal(amount);
        this.amounts.push(price);
      }
    },



    async save() {
      try {
        let entity = JSON.parse(JSON.stringify(this.source));
        entity.productId = this.selectedProduct.id;
        entity.priceType = this.selectedPriceType.id;
        entity.priceUnit = this.selectedPriceUnit.id;
        entity.description = this.description;
        entity.name = this.name  || "";

        entity.amounts = [];
        for (let amount of this.amounts) {
          entity.amounts.push(parseFloat(amount));
        }

        await this.service.update(
          entity
        );

        this.source = JSON.parse(JSON.stringify(entity));

        this.disableAlertQuit();
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }
    },

    pricePositiveRules(value) {
      if (value) {
        if(parseFloat(value) < 0 ) {
          return "pas de valeur négative";
        }
      }
      return true;
    },

    // Evènement de unfocus des maontant min et max pour afficher les valeurs formatées
    onQuitAmount(index) {
      this.amounts[index] = numberWithMinTwoDecimal(parseFloat(this.amounts[index]));
    },

    resetAmounts() {
      this.amounts = [];
    },

    /** Contrôle de montants dans l'ordre croissant */
    pricesCroissant(value) {
      if (value == 0) {
        if (parseFloat(this.amounts[value]) > parseFloat(this.amounts[value+1])) {
            return "Mettre les montants dans l'ordre croissant";
        }
      } else if (value == this.amounts.length-1) {
        if (parseFloat(this.amounts[value]) < parseFloat(this.amounts[value-1])) {
            return "Mettre les montants dans l'ordre croissant";
        }
      }else {
        if (parseFloat(this.amounts[value]) > parseFloat(this.amounts[value+1])
        || parseFloat(this.amounts[value]) < parseFloat(this.amounts[value-1]) ) {
            return "Mettre les montants dans l'ordre croissant";
        }
      }

      return true;
    }

  },
  computed: {
    completed() {
      if (!this.selectedProduct) return false;
      if (!this.selectedPriceType) return false;
      if (!this.selectedPriceUnit) return false;

      if (this.selectedPriceType.nbAmount != this.amounts.length) return false;
      // Vérifie que chacun des montant soit complété
      for (let amount of this.amounts) {
        if (!amount || amount == "") return false;
      }
      // Contrôle que les montant soit dans l'ordre croissants
      if (this.pricesCroissant() != true) return false;

      return true;
    },

    /** Retourne les produit disponibles (ceux qui n'ont pas de tarifs) */
    availableProducts() {
      let available = [];

      if (!this.loading) {

        // Récupère les ids de produits déjà utilisés
        let ids = this.allPricesGroup.map((p) => p.productId);
        // On ne garde que les produits qui n'ont pas de tarifs
        available = this.allProducts.filter((p) => !ids.includes(p.id));

        // Dans le cas de présence d'un produit source
        if (this.source) {
          // On tente de la retrouver dans les produit disponibles
          let found = available.find((a) => a.id == this.source.productId);

          // Si les produits ne contiennent pas le produit source, on l'ajoute 
          if (!found) {
            let prod = this.allProducts.find((p) => p.id == this.source.productId);
            available.push(prod);
          }
        }
        // Tri des produits par nom digital
        available.sort(function(a, b) {
          return a.digitalName.localeCompare(b.digitalName);
        });
      }

      return available;
    },

     /** Retourne le nombre de champs à généré pour les montants */
    availableAmount() {
      let nbAmount = 0;
      
      if (this.selectedPriceType) {
        
        if (this.selectedPriceType.id != this.source.priceType) {
          this.resetAmounts();
        }
        nbAmount = this.selectedPriceType.nbAmount;
      }

      return nbAmount;
    },

    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (this.source.productId != this.selectedProduct.id) {
        changed = true;
      }
      if (!areTheSame(this.source.name, this.name)) {
        changed = true;
      }
      if (!areTheSame(this.source.description, this.description)) {
        changed = true;
      }
      if (this.selectedPriceType.id != this.source.priceType) {
        changed = true;
      }
      // Vérification des montants
      for(let i = 0; i < this.amounts.length; i++) {
        
        let amount = numberWithMinTwoDecimal(parseFloat(this.amounts[i]));
        let amountSource = numberWithMinTwoDecimal(parseFloat(this.source.amounts[i]));

        if (amount != amountSource) {
          changed = true;
        }
      }
      
      if (this.selectedPriceUnit.id != this.source.priceUnit) {
        changed = true;
      }

      return changed;
    },
  },
  mounted() {

    // Instanciation des services
    this.service = new ProductPriceGroupeService(this.$api.getProductPricesGroupe());
    this.serviceProduct = new AvecProductsService(this.$api.getTransmissionsAvecProductsApi());
    this.servicePriceTypes = new PriceTypesService(this.$api.getPriceTypesApi());
    this.servicePricesUnits = new PriceUnitsService(this.$api.getPriceUnitsApi());

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>